import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin, FaCircleNotch} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"



function handleInputChange(){
  var fname = document.getElementById("fname").value;
  sessionStorage.setItem("fname", fname);

}


const sendEmail = event =>{

  var cform = {
    fname: document.getElementById("fname").value,
    email: document.getElementById("email").value,
    message: document.getElementById("message").value
  }
  console.log(cform);
  var submitBtn = document.getElementById("submitBtn");
  submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
  submitBtn.classList.add("form-submitting");

  var body = {
    Token: 'phHRMocDrnRvQgKl0zcjMOhD8ZeaWrnn2local',
    FormData: JSON.stringify(cform)   
  };
  fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => 
    response.text().then(function (responseText) {
      console.log("response text: " + responseText);
      if(responseText.localeCompare('"Form sent successfully."') === 0){
        //success send message
        console.log("success"+ responseText);
        submitBtn.innerHTML = "Message sent!";
        submitBtn.classList.remove("form-submitting");
      }else{
        //error sending message
        console.log("something went wrong");
        submitBtn.innerHTML = "Ups... Try again!";
        submitBtn.classList.remove("form-submitting");
      }
        

    })
  )
  // console.log("Message sent");

  event.preventDefault();
}


const ContactPage = () => (

  

  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    
   

    render={data => (
      
    <Layout type="contact">
        <SEO title="Contact us" />


        <section className="contact-form-section">

          <h1>Get in touch with us</h1>

          <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-1-2@m  uk-flex-last@m">

              <div className="uk-child-width-1-1 section-content uk-grid-row-medium uk-grid-column-collapse@m">
                <div className="beauty-divider"></div>

                <div className="contact-details-line">
                  <FaEnvelope className="fa-icons"/>
                  <a href="mailto:info@fingersnap.it">info@fingersnap.it</a>
                </div>
                <div className="contact-details-line">
                  <FaLocationArrow className="fa-icons"/>
                  Hoghton Towers, Hoghton Street, Southport, PR90TB
                </div>


                {/* <div className="space-separator"></div>
                <div className="beauty-divider"></div> */}

                {/* <div className="contact-social-media">
                  <Link to="/#0"><FaFacebook className="fa-icons"/></Link>
                  <Link to="/#0"><FaLinkedin className="fa-icons"/></Link>
                </div> */}
              </div>
              
            </div>
    
            <div className="uk-width-1-2@m ">
              <div className="uk-child-width-1-1 section-content uk-grid-row-medium uk-grid-column-collapse@m">
                <div className="card uk-card uk-card-body uk-grid-match">
                  <form id="fin-cform" onSubmit={sendEmail}>
                    <label htmlFor="fname">First name</label>
                    <input type="text" id="fname" name="fname" onChange={handleInputChange} required/>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" required/>
                    <div className="space-separator"></div>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" rows="3" required></textarea>
                    <button id="submitBtn" type="submit" value="Submit" className="submit-btn cta-primary-btn accent ">Submit<FaCircleNotch className="spinner"/></button>
                  </form>
                </div> 
              </div>
            </div>
          </div>
          
        </section>


      </Layout>

    )}
  
  />
)

export default ContactPage
